import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import Image1 from "./image1";

export const IndexPageTemplate = ({
  image,
  title,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <section className="hero is-fullheight-with-navbar">
      <div className="hero-body">
        <div className="container">
          <div className="parent">
            <div className="child">
              <Image1 />
            </div>
            <div className="child content">
              <h1 className="has-text-left" style={{ fontSize: "2em" }}>
                <span style={{ color: "#6ba696" }}>όψεις</span>{" "}
                <span style={{ color: "#bcbec0" }}>ψυχολογικό κέντρο</span>
              </h1>
              <p className="has-text-left jjy"> {description}</p>
              <div style={{ display: "flex" }}>
                <Link
                  title="Go to About section"
                  className="button is-light mt-4"
                  style={{ background: "#6ba696", color: "#fff" }}
                  to="/about"
                >
                  Περισσότερα
                </Link>{" "}
                <Link
                  title="Go to Contact section"
                  className="button is-light mt-4 ml-2"
                  to="/contact"
                >
                  Επικοινωνία
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div
          className="content"
          style={{
            display: "grid",
            placeItems: "center",
            minHeight: "90vh",
          }}
        >
          <a href="services#services-adults">
            <h1 style={{ color: "#6ba696" }}>Υπηρεσίες Ενηλίκων</h1>
          </a>
          <p className="">Ατομική Ψυχοθεραπεία </p>
          <p className="">Ατομική Συμβουλευτική</p>
          <p className="">Συμβουλευτική Ζεύγους</p>
          <p className="">Ομαδική Ψυχοθεραπεία</p>
          <p className="">Εξ’ Aποστάσεως Ψυχοθεραπεία</p>{" "}
          <a href="services#services-teenagers">
            <h1
            className="onm"
              style={{
                color: "#6ba696",
                marginTop: "3rem",
              }}
            >
              Υπηρεσίες Παιδιών και Εφήβων
            </h1>
          </a>
          <p>Ψυχολογική Αξιολόγηση Παιδιών και Εφήβων</p>
          <p>Ψυχοθεραπεία Παιδιών και Εφήβων</p>
          <p>Συμβουλευτική Γονέων</p>
          <p>Ομάδες Διάδρασης </p>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div className="columns my-6">
          <div className="column is-10 is-offset-1">
            {" "}
            <div className="content">
              <h1
                style={{
                  color: "#6ba696",
                  textAlign: "center",
                  marginBottom: "1.5em",
                }}
              >
                Συχνές Ερωτήσεις
              </h1>
              <BlogRoll />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
